.custom-ion-alert {
 
  --border-radius: 0px;
  --header-background-color: #f0f0f0;
  --header-color: #000000;
  --border-color: #000000;
  /* --max-width: 350px;  */
--padding:8px;
}

.custom-ion-alert .alert-button-group {
  display: flex;
  justify-content: flex-end;
  border-radius: 15px;
  text-transform: none; /* Reset any text transformations */

}

.custom-ion-alert .alert-button-group button {
  margin: 0 10px;
  text-transform: none; /* Reset any text transformations */

  /* font-size: 15px; */
}

.custom-ion-alert .alert-button-group button:first-child {
  text-transform: none; /* Reset any text transformations */
    font-size: larger;
}

.custom-ion-alert .alert-button-group button:last-child {
	background-color: var(--ion-color-primary);
	color: var(--ion-color-primary-contrast);
	border-radius: 20px;
	padding: 0 30px;
  text-transform: none; /* Reset any text transformations */

}

.custom-ion-alert .alert-wrapper {
 

}

.custom-ion-alert .alert-wrapper .alert-message {
  /* text-align: center; */
  /* font-size: 20px; */
}
.custom-ion-alert .alert-wrapper .alert-title {
  /* text-align: center; */

   /* color: red; */
   display: flex;
   justify-content: flex-start;
  /* font-size: 25px; */
}

