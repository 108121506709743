.inside:hover{
    background-color: rgb(196, 223, 231);
    color: darkblue;
}

.accord {
    
     /* position: relative; */
   
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:40000;
}

.mainaccord {
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0; */
  width: 40vw;
    border-radius: 10px;
    
}
.mainaccord h5{
    border-radius: 10px;
}


