/* .toast-success{
 --color:var(--ion-color-secondary);
}
.toast-error{
    --color:var(--ion-color-danger);
} */

.toast{
    --background: var(--ion-color-light);
    --width: fit-content;
    --color: var(--ion-color-dark);
}