
.login-card{
    margin: 30px auto;
    padding: 20px;
    border-radius: 5px;
    background-color: var(--ion-color-medium-contrast);
    height: min-content;
    border: 1px solid #eeeeee;
}

.logo{
    width: 100px;
}

@media screen and (max-width: 410px){
    .container{
        height: calc(100vh - 58px);
        display: flex;
    }
    
}