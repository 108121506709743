ion-menu ion-content {
    --padding-top: 20px;
    --padding-bottom: 20px;
  
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
  }
  
  /* Remove background transitions for switching themes */
  ion-menu ion-item {
    --transition: none;
  }
  
  ion-item.selected {
    --color: var(--ion-color-primary);
  }
  
  /*
   * Material Design Menu
  */

  
  ion-menu.md ion-list-header {
    padding-left: 18px;
    padding-right: 18px;
  
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 450;
  }
  
  ion-menu.md ion-item {
    --padding-start: 18px;
  
    margin-right: 10px;
  
    border-radius: 0 50px 50px 0;
  
    font-weight: 500;
  }
  
  ion-menu.md ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.14);
  }
  
  ion-menu.md ion-item.selected ion-icon {
    color: var(--ion-color-primary);
  }
  
  ion-menu.md ion-list-header,
  ion-menu.md ion-item ion-icon {
    color: var(--ion-color-step-650, #5f6368);
  }
  
  ion-menu.md ion-list:not(:last-of-type) {
    border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
  }
  
  
  /*
   * iOS Menu
  */
  ion-menu.ios ion-list-header {
    padding-left: 16px;
    padding-right: 16px;
  
    margin-bottom: 8px;
  }
  
  ion-menu.ios ion-list {
    padding: 20px 0 0;
  }
  
  ion-menu.ios ion-item {
    --padding-start: 16px;
    --min-height: 50px;
  }
  
  ion-menu.ios ion-item ion-icon {
    font-size: 24px;
    color: #73849a;
  }
  
  ion-menu.ios ion-item.selected ion-icon {
    color: var(--ion-color-primary);
  }

  /* added */
  .sidemenu {
    width: 300px !important;
  }
  




  .selected {
    background-color: #f0f0f0; /* Change this to the desired background color */
    color: #05A7E5; /* Change this to the desired text color */
    font-weight: bold; /* Change this to the desired font weight */
}

.selected ion-icon {
    color: #333; /* Change this to the desired icon color */
}
/* Adjust the colors */
  



/* .selected {
    background-color: #a2deed; /* Change this to any color you want */
    /* color: #239cd8;
} */

/* .blueIcon { */
    /* color: rgb(65, 194, 234);
} */ 
