
           
.responsive-container {
  overflow-y: scroll;
  width: 100%;
  max-width: 250px;
  height: 100px;
  margin: auto;
  
}
.responsive-container::-webkit-scrollbar {
    width: 8px; /* Adjust the width as needed */
  }
  
  .responsive-container::-webkit-scrollbar-thumb {
    background-color: rgb(201, 205, 236); /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners of the thumb */
  }
@media screen and (max-width: 600px) {
  .responsive-container {
    display: none;
  }
}

/* styles.css */

/* checking for git */

@media (max-width: 700px) {
  .hideOnMobile {
    display: none;
  }

  
}

@media (min-width: 699px) {
  .showOnMobile {
    display: none;
  }

  
}
            